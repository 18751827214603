import {
  EvaluationCriteria,
  EvaluationRelevantInstance,
  EvaluationResults,
} from "types/EvaluationResults";
import { EvaluationMethod } from "~/classes/evaluation_criteria";

export const evaluateCriteriaTree = (evaluationResults: EvaluationResults) => {
  if (!evaluationResults.evaluationResult) return;

  for (var criteria of evaluationResults.evaluationResult) {
    criteria.passed = evaluateCriteria(criteria);

    if (criteria.originalPassed == undefined) {
      criteria.originalPassed = criteria.passed;
    }
  }

  return evaluationResults;
};

const evaluateCriteria = (criteria: EvaluationCriteria) => {
  if (criteria.isOverriden) {
    return criteria.passed;
  }

  // If the criteria has sub criteria then relevant instances are require and not having relevant instances is
  // actually a sign of failure that will be caught in the next logic
  // If the criteria doesn't have sub criteria then we can just return the original value

  if (
    criteria.criteria.length == 0 ||
    !criteria.relevantInstances ||
    criteria.relevantInstances.length == 0
  ) {
    return criteria.passed;
  }

  let numValidInstances = 0;

  for (var instance of criteria.relevantInstances) {
    // If this criteria requires instances to be reviewed, then we will skip checking whether this instance is valid until the user has specified
    if (
      criteria?.config?.isManual != true &&
      (criteria?.config?.manualInstanceReview != true ||
        instance.isReviewed == true)
    ) {
      instance.valid = evaluateInstance(instance);
      numValidInstances += instance.valid ? 1 : 0;
    }
  }

  // If the evaluation method is all then we need to make sure that all instances are valid
  if (criteria.config?.evaluationMethod == EvaluationMethod.all) {
    return (
      // I made a change to also check that this criteria has more than one valid instance
      // Otherwise this might pass if a criteria that requires at least one valid instance actually has 0
      numValidInstances > 0 &&
      numValidInstances == criteria.relevantInstances.length
    );
  }

  // This is the equivalent of saying the default evaluation method is instances
  return numValidInstances > 0;
};

const evaluateInstance = (instance: EvaluationRelevantInstance): boolean => {
  // If this instance doesn't have any sub criteria then we can return whatever the
  // original value was. A user can't override this

  if (!instance.criteria || instance.criteria.length == 0) {
    return instance.valid;
  }

  // An instance is valid if all of it's criteria are also valid.
  let numValidCriteria = 0;

  // Mechanics criteria do not contribute to the validity of an instance
  const nonMechanicsCriteria = instance.criteria.filter(
    (criteria) => criteria.config?.isMechanics != true
  );

  for (var criteria of nonMechanicsCriteria) {
    criteria.passed = evaluateCriteria(criteria);

    if (criteria.originalPassed == undefined) {
      criteria.originalPassed = criteria.passed;
    }

    numValidCriteria += criteria.passed ? 1 : 0;
  }

  return numValidCriteria == nonMechanicsCriteria.length;
};
