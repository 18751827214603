export const useDoesRelevantInstanceHaveActiveSubtype = ({
  relevantInstance,
  documentId,
  activeTypesOverride,
  activeSubTypesOverride,
  debug = false,
}: {
  relevantInstance: EvaluationRelevantInstance;
  documentId: string;
  activeTypesOverride?: string[];
  activeSubTypesOverride?: string[];
  debug: boolean;
}) => {
  const documentActiveTypeStore = useDocumentActiveTypeStore(documentId);
  const { activeTypes: documentActiveTypes, activeSubTypes } = storeToRefs(
    documentActiveTypeStore
  );

  const activeTypes = activeTypesOverride || documentActiveTypes.value;

  return computed(() => {
    let hasActiveSubType = false;
    let hasNoSubTypes = true;

    const subTypeOverrides = activeSubTypesOverride || activeSubTypes.value;

    if (subTypeOverrides.length == 0) return 0;

    for (let relevantInstanceToken of relevantInstance?.tokens ?? []) {
      const tokenStore = useDocumentTokenIdPositionMapStore(
        documentId
      ).tokenStoreFromId(relevantInstanceToken.id);
      const { token: tokenRef } = storeToRefs(tokenStore);

      if (tokenRef.value == undefined) continue;

      const subTypes = tokenRef.value.indicators?.subTypes || [];
      const relevantSubTypes = subTypes.filter((subType: string) => {
        for (let activeType of activeTypes) {
          var activeTypeSubTypes = useGetTypeSubtypes(activeType);
          if (activeTypeSubTypes && activeTypeSubTypes.includes(subType)) {
            return true;
          }
        }

        return false;
      });

      if (debug) {
        console.log("Subtype Overrides: ", subTypeOverrides);
        console.log("Relevant Subtypes: ", relevantSubTypes);
      }

      if (relevantSubTypes.length > 0) {
        hasNoSubTypes = false;
        if (
          subTypeOverrides.some((activeSubType) =>
            relevantSubTypes.includes(activeSubType)
          )
        ) {
          hasActiveSubType = true;
        }
      }
    }

    if (hasActiveSubType) return 1; // Matches activeSubType context
    if (hasNoSubTypes) return 0; // No subTypes
    return -1; // SubTypes that are not in the active context
  });
};
