export const useDocumentTokenIdPositionMap = (
  documentId: string,
  revisionId: string
) =>
  definePiniaStore(`/document/${documentId}/${revisionId}/token/id/map`, () => {
    const tokenIdPositionMap = ref<{ [key: string]: number }>({});
    const tokenIdToLocalPositionMap = ref<{ [key: string]: number }>({});
    const tokenLocalPositionToIdMap = ref<{ [key: number]: string }>({});
    const tokenLocalIndexToIdMap = ref<{ [key: number]: string }>({});

    const mapToken = (token: ParagraphToken) => {
      tokenIdPositionMap.value[token.id] = token.globalPosition;
      tokenIdToLocalPositionMap.value[token.id] = token.localPosition;
      tokenLocalPositionToIdMap.value[token.localPosition] = token.id;
      tokenLocalIndexToIdMap.value[token.position] = token.id;
    };

    const tokenStoreFromId = (id: string) => {
      const position = tokenIdPositionMap.value[id];
      return useParagraphTokenStore(documentId, position);
    };

    const tokenGlobalPositionFromId = computed(() => {
      return (id: string) => {
        return tokenIdPositionMap.value[id];
      };
    });

    const tokenLocalPositionFromId = computed(() => {
      return (id: string) => {
        return tokenIdToLocalPositionMap.value[id];
      };
    });

    const tokenIdFromLocalPosition = computed(() => {
      return (localPosition: number) => {
        return tokenLocalPositionToIdMap.value[localPosition];
      };
    });

    const tokenGlobalPositionFromLocalIndex = computed(() => {
      return (localIndex: number) => {
        const id = tokenLocalIndexToIdMap.value[localIndex];
        return tokenIdPositionMap.value[id];
      };
    });

    const tokenIdFromLocalIndex = computed(() => {
      return (localIndex: number) => {
        return tokenLocalIndexToIdMap.value[localIndex];
      };
    });

    const reset = () => {
      tokenIdPositionMap.value = {};
    };

    return {
      mapToken,
      tokenStoreFromId,
      reset,
      tokenGlobalPositionFromId,
      tokenLocalPositionFromId,
      tokenIdFromLocalPosition,
      tokenGlobalPositionFromLocalIndex,
      tokenIdFromLocalIndex,
      tokenLocalIndexToIdMap,
    };
  });
