declare const gapi: any;
declare const google: any;

export default defineNuxtPlugin((nuxtApp) => {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  script.onload = () => onApiLoad();
  document.head.appendChild(script);

  const script2 = document.createElement("script");
  script2.src = "https://accounts.google.com/gsi/client";
  script2.onload = () => gisLoaded();
  document.head.appendChild(script2);

  const script3 = document.createElement("script");
  script3.src = `https://maps.googleapis.com/maps/api/js?key=${
    useRuntimeConfig().public.googleApiKey
  }&libraries=places`;
  script3.async = true; // Add this line to load the script asynchronously
  script3.onload = () => mapsLoaded();
  document.head.appendChild(script3);

  const onApiLoad = () => {
    gapi.load("picker", () => {});

    gapi.load("client", () => {
      gapi.client.load("drive", "v3", () => {});
    });
  };

  const gisLoaded = () => {
    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: useRuntimeConfig().public.googleClientId,
      scope:
        // This scope was intented to allow access to all drive files so a user could import them into gradebook
        // "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.file",
        // This scope only allows google docs
        "https://www.googleapis.com/auth/drive.file",
      callback: "", // defined later
    });

    const tokenClientState = useState("tokenClient");
    tokenClientState.value = tokenClient;

    (window as any).tokenClient = tokenClient;
  };

  const mapsLoaded = () => {};
});
