import { storeToRefs } from "pinia";
import { EvaluativeParagraphs } from "types/EvaluativeParagraph";
import { Paragraphs } from "types/Paragraph";
import { useAnnotationFormState } from "./useAnnotationFormState";

export const useDocumentSelection = (documentId: string) =>
  definePiniaStore(`${documentId}/document/selection`, () => {
    const startPosition = ref<number | undefined>();
    const endPosition = ref<number | undefined>();
    const customPositions = ref<number[]>([]);

    const annotationFormStateStore = useAnnotationFormState();

    const { isAnnotationBeingEdited } = storeToRefs(annotationFormStateStore);

    const documentViewModeStore = useDocumentViewModeStore(documentId);
    const { canSelect } = storeToRefs(documentViewModeStore);

    const setCustomSelection = (positions: number[]) => {
      customPositions.value = positions;
    };

    const setSelection = (
      startGlobalPosition: number | null | undefined,
      endGlobalPosition: number | null | undefined
    ) => {
      // If an annotation is being edited, block and updates.
      if (isAnnotationBeingEdited.value) {
        return;
      }

      startPosition.value =
        startGlobalPosition != undefined ? startGlobalPosition : undefined;
      endPosition.value =
        endGlobalPosition != undefined ? endGlobalPosition : undefined;

      if (startPosition.value == undefined && endPosition.value == undefined) {
        customPositions.value = [];
      }
    };

    const toggleCustomPosition = (position: number) => {
      if (customPositions.value.includes(position)) {
        customPositions.value = customPositions.value.filter(
          (p) => p != position
        );
      } else {
        customPositions.value = [...customPositions.value, position];
      }
    };

    const clearSelection = () => {
      setSelection(undefined, undefined);
      customPositions.value = [];
    };

    const isTokenSelected = computed(() => {
      return (tokenPosition: number) => {
        if (!hasSelection.value) {
          return false;
        }

        const values = [startPosition.value!, endPosition.value!];
        values.sort((a, b) => a - b);

        return (
          (values[0] <= tokenPosition && values[1] >= tokenPosition) ||
          customPositions.value.includes(tokenPosition)
        );
      };
    });

    const hasStartId = computed(() => {
      return (
        startPosition.value != undefined &&
        Number.isNaN(startPosition.value) == false
      );
    });

    const hasEndId = computed(() => {
      return (
        endPosition.value != undefined &&
        Number.isNaN(endPosition.value) == false
      );
    });

    const hasSelection = computed(() => {
      return hasStartId.value && hasEndId.value;
    });

    const selectedTokenPositions = computed(() => {
      const start = startPosition.value;
      const end = endPosition.value;

      const positions = [...customPositions.value];

      if (start == undefined || end == undefined) {
        return positions;
      }

      const values = [start, end];
      values.sort((a, b) => a - b);

      let index = values[0];

      const tokenPositions: number[] = [];

      while (index <= values[1]) {
        tokenPositions.push(index);
        index++;
      }

      // tokenPositions.push(...customPositions.value);

      return [...tokenPositions, ...customPositions.value];
    });

    const selectedTokens = computed((): ParagraphTokens => {
      const tokens: ParagraphTokens = []; // You can also directly annotate the type here.

      for (const tokenGlobalPosition of selectedTokenPositions.value) {
        const tokenStore = useParagraphTokenStore(
          documentId,
          tokenGlobalPosition
        );
        const token = tokenStore.token;

        if (token === undefined) continue; // Using strict equality is a good practice.

        tokens.push(token);
      }

      return tokens;
    });

    const selectedParagraphs = computed(() => {
      const paragraphIds = [] as string[];
      const paragraphs = [] as Paragraphs;

      for (const tokenPosition of selectedTokenPositions.value) {
        const tokenStore = useParagraphTokenStore(documentId, tokenPosition);
        const paragraphId = tokenStore.paragraphId;

        if (paragraphId == undefined) continue;

        if (paragraphIds.includes(paragraphId)) continue;

        paragraphIds.push(paragraphId);

        const paragraphStore = useDocumentParagraphStore(
          documentId,
          paragraphId
        );
        const paragraph = paragraphStore.paragraph;

        if (paragraph == undefined) continue;

        paragraphs.push(paragraph);
      }

      return paragraphs;
    });

    const documentEvaluativeParagraphs =
      useDocumentEvaluativeParagraphsStore(documentId);
    const { getEvaluativeParagraphByTokenIndex } = storeToRefs(
      documentEvaluativeParagraphs
    );

    const selectedEvaluativeParagraphs = computed(() => {
      const evaluativeParagraphs = [] as EvaluativeParagraphs;

      for (const tokenPosition of selectedTokenPositions.value) {
        const token = useParagraphTokenStore(documentId, tokenPosition).token;

        if (!token) continue;

        const evaluativeParagraph = getEvaluativeParagraphByTokenIndex.value(
          token.position
        );

        if (evaluativeParagraph == undefined) continue;

        // Push evaluative paragraph if not already added
        if (
          evaluativeParagraphs.findIndex(
            (p) => p.id == evaluativeParagraph.id
          ) < 0
        ) {
          evaluativeParagraphs.push(evaluativeParagraph);
        }
      }

      return evaluativeParagraphs;
    });

    const hasMultipleTokensSelected = computed(() => {
      return selectedTokenPositions.value.length > 1;
    });

    // Get every span that has the token-selected cclass and remove that class
    // Then loop thorugh the selectedToken positions and get any token with data-token-position and add
    // token-selected class
    watch(customPositions, () => {
      // useApplyClassToTokens(selectedTokenPositions.value, "token-selected");

      useApplyClassToTokens(
        customPositions.value,
        "custom-selection",
        ".selection-layer"
      );
    });

    const documentShowFormattingStore =
      useDocumentShowFormattingStore(documentId);
    const { shouldShowFormatting } = storeToRefs(documentShowFormattingStore);

    const _evaluativeParagraphIndexToHighlight = ref<number | undefined>();
    const setEvaluativeParagraphIndexToHighlight = (
      index: number | undefined
    ) => {
      _evaluativeParagraphIndexToHighlight.value = index;
    };

    const evaluativeParagraphIndexToHighlight = computed(() => {
      if (shouldShowFormatting.value != true) return undefined;

      return _evaluativeParagraphIndexToHighlight.value;
    });

    const clearEvaluativeParagraphIndexToHighlight = () => {
      _evaluativeParagraphIndexToHighlight.value = undefined;
    };

    return {
      startPosition,
      endPosition,
      hasSelection,
      isTokenSelected,
      selectedTokenPositions,
      selectedTokens,
      selectedParagraphs,
      selectedEvaluativeParagraphs,
      hasMultipleTokensSelected,
      setSelection,
      setCustomSelection,
      toggleCustomPosition,
      clearSelection,
      evaluativeParagraphIndexToHighlight,
      setEvaluativeParagraphIndexToHighlight,
      clearEvaluativeParagraphIndexToHighlight,
    };
  });
