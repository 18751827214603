export const useDocumentShowFormatting = (
  documentId: string,
  revisionId: string
) =>
  definePiniaStore(
    `/document/${documentId}/revision/${revisionId}/show/formatting`,
    () => {
      const shouldShowFormatting = ref(false);

      const setShouldShowFormatting = (value: boolean) => {
        shouldShowFormatting.value = value;
      };

      const toggleShouldShowFormatting = () => {
        shouldShowFormatting.value = !shouldShowFormatting.value;
      };

      return {
        shouldShowFormatting,
        setShouldShowFormatting,
        toggleShouldShowFormatting,
      };
    }
  );
