import { addDoc, collection } from "firebase/firestore";
import { ChecklistCriteria } from "types/Checklist";
import { EvaluationTest } from "types/EvaluationTest";

export const useReportEvaluationProblem = async (
  documentId: string,
  revisionId: string,
  checklistId: string,
  checklistTitle: string,
  problem: any,
  description: string | undefined,
  isControl: boolean,
  checklistCriteriaId: string | undefined,
  checklistCriteria: ChecklistCriteria | undefined
) => {
  const time = new Date().getTime();

  const db = useFirestore();
  const collectionRef = collection(db, "evaluationTests");

  const response = await addDoc(collectionRef, {
    documentToTest: {
      source: "pending",
    },
    metricId: checklistCriteria?.evaluationMetricId ?? "undefined",
    status: EvaluationTestStatus.requiresReview,
    createdAtTimestamp: time,
    updatedTimestamp: time,
    originalReportedProblem: {
      documentId: documentId,
      revisionId: revisionId,
      checklistId: checklistId,
      checklistName: checklistTitle,
      checklistCriteriaId: checklistCriteriaId,
      checklistCriteriaName: checklistCriteria?.label ?? "undefined",
      userId: useCurrentUID(),
      userEmail: useCurrentUserEmail(),
      problem,
    },
    description,
    isControl,
  } as EvaluationTest);

  console.log(response.id);
};
