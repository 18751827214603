export const useGetTypeSubtypes = (type: string) => {
  return {
    dressUp: [
      "lyAdverb",
      "whoWhichClause",
      "strongVerb",
      "qualityAdjective",
      "www.asia",
      "www.asia.b",
    ],
    sentenceOpeners: [
      "subject",
      "prepositional",
      "lyAdverb",
      "ing",
      "clausal",
      "vss",
    ],
    decoration: [] as string[],
  }[type];
};
