// Ok this store will be all about fetching paragraphs, not streaming them. we're going to fetch them once andthat's it.

import {
  WriteBatch,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { Paragraph, Paragraphs } from "types/Paragraph";

export const useDocumentParagraphs = (documentId: string, revisionId: string) =>
  definePiniaStore(`/${documentId}/${revisionId}/paragraphs`, () => {
    const allParagraphs = ref<Paragraph[]>([]);
    const paragraphIdToBodyPositionMap = ref<{ [key: string]: number }>({});
    const isMissingAssignment = ref(false);

    const fetchParagraphs = async (collectionRef: string) => {
      const db = useFirestore();

      const paragraphsRef = collection(
        db,
        `/documents/${documentId}/revisions/${revisionId}/${collectionRef}`
      );

      const paragraphsQuery = query(paragraphsRef, orderBy("index", "asc"));

      const paragraphsSnapshot = await getDocs(paragraphsQuery);

      const paragraphs = [] as Paragraphs;
      for (var doc of paragraphsSnapshot.docs) {
        const paragraph = {
          id: doc.id,
          ...doc.data(),
        } as Paragraph;

        paragraphs.push(paragraph);
      }

      return useTrimParagraphs(paragraphs);
    };

    const initializeParagraphs = async () => {
      allParagraphs.value = [];
      const paragraphStates = [] as any[];

      useDocumentTokenIdPositionMapStore(documentId).reset();

      const sections = await fetchParagraphs("sections");

      for (var paragraph of sections) {
        paragraphStates.push({
          ...paragraph,
        });
        paragraph.tokens = [] as ParagraphTokens;
        allParagraphs.value.push(paragraph);
      }

      const paragraphs = await fetchParagraphs("paragraphs");

      for (var paragraph of paragraphs) {
        paragraphStates.push({
          ...paragraph,
        });
        paragraph.tokens = [] as ParagraphTokens;
        allParagraphs.value.push(paragraph);
      }

      if (
        paragraphs.length == 0 &&
        useSubmittedDocumentStore(documentId).submittedDocument
          ?.doesNOTExpectAssignment != true
      ) {
        isMissingAssignment.value = true;
      }
    };

    const saveTokens = (tokens: ParagraphTokens) => {
      const paragraphIdsToSave: string[] = [];

      for (var token of tokens) {
        const tokenGlobalPosition = token.globalPosition;
        const tokenStore = useParagraphTokenStore(
          documentId,
          tokenGlobalPosition
        );
        const paragraphId = tokenStore.paragraphId;

        if (!paragraphId) continue;

        const paragraphStore = useDocumentParagraphStore(
          documentId,
          paragraphId
        );

        if (!paragraphStore.paragraph) continue;

        const tokenIndex = paragraphStore.paragraph.tokens.findIndex((t) => {
          return t.globalPosition === tokenGlobalPosition;
        });

        if (tokenIndex < 0) continue;

        paragraphStore.paragraph.tokens[tokenIndex] = token;
        paragraphIdsToSave.push(paragraphId);

        tokenStore.setToken(token);
      }

      const uniqueParagraphIdsToSave = [...new Set(paragraphIdsToSave)];

      const paragraphsToSave = [] as Paragraphs;
      const paragraphStates = [] as string[];

      for (var paragraphId of uniqueParagraphIdsToSave) {
        const paragraphStore = useDocumentParagraphStore(
          documentId,
          paragraphId
        );
        const paragraph = paragraphStore.paragraph;

        if (paragraph) {
          paragraphsToSave.push(paragraph);
        }
      }

      useDocumentHistoryStore(documentId).saveParagraphsState(paragraphsToSave);
      useDocumentRevisionParagraphsStore(documentId).saveParagraphs(
        paragraphsToSave
      );
    };

    const { save, saved } = useDocumentSavingStatusStore(documentId);

    const saveParagraph = async (paragraph: Paragraph) => {
      const db = useFirestore();
      var paragraphRef = doc(
        db,
        `/documents/${documentId}/revisions/${revisionId}/${paragraph.collectionRef}/${paragraph.id}`
      );

      await updateDoc(paragraphRef, paragraph);
    };

    const saveParagraphs = async (
      paragraphs: Paragraphs,
      initialBatch: WriteBatch | undefined,
      saveState: boolean = false
    ) => {
      var startTime = new Date().getTime();

      const db = useFirestore();

      var batch = initialBatch ?? writeBatch(db);

      if (initialBatch == undefined) {
        save();
      }

      for (var paragraph of paragraphs) {
        var collectionRef = paragraph.collectionRef;
        var paragraphRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/${collectionRef}/${paragraph.id}`
        );

        batch.update(paragraphRef, paragraph);
      }

      if (saveState == true) {
        useDocumentHistoryStore(documentId).saveParagraphsState(paragraphs);
      }

      if (initialBatch == undefined) {
        await batch.commit();

        saved();
      }
    };

    const paragraphs = computed(() => {
      return allParagraphs.value.filter((paragraph) => {
        return paragraph.isDeleted != true;
      });
    });

    // It is possible for there to be paragraphs that are accidentally split
    // In this case, we want to assign the bodyParagraphPosition index based on
    // Where the user has marked uninterrupted paragraphs.
    const bodyParagraphs = computed((): Paragraphs => {
      var allBodyParagraphs = paragraphs.value.filter((p) => {
        return p.collectionRef == "paragraphs" && p.section == "body";
      }) as Paragraphs;

      let currentBodyPosition = 0;
      let lastParagraphPosition = null;

      for (var paragraph of allBodyParagraphs) {
        if (paragraph.paragraphPosition !== lastParagraphPosition) {
          lastParagraphPosition = paragraph.paragraphPosition;
          paragraph.bodyParagraphPosition = currentBodyPosition;
          currentBodyPosition++;
        } else {
          paragraph.bodyParagraphPosition = currentBodyPosition - 1;
        }
      }

      return allBodyParagraphs;
    });

    const numBodyParagraphs = computed(() => {
      const paragraphPositions = bodyParagraphs.value.map((paragraph) => {
        return paragraph.bodyParagraphPosition ?? paragraph.index;
      });

      const uniquePositions = [...new Set(paragraphPositions)];

      return uniquePositions.length;
    });

    // MERGE
    const paragraphsToMerge = ref<Paragraphs>([]);
    const isMergingParagraphs = computed(() => {
      return paragraphsToMerge.value.length > 0;
    });

    const toggleParagraphToMerge = (paragraph: Paragraph) => {
      const isMerging = isParagraphToBeMerged.value(paragraph);

      if (isMerging == true) {
        paragraphsToMerge.value = paragraphsToMerge.value.filter(
          (p) => p.id != paragraph.id
        );
      } else {
        paragraphsToMerge.value = [...paragraphsToMerge.value, paragraph];
      }
    };

    // First I'll sort paragraphs by index
    // Then I'll get the paragraphPositions
    // Then I'll get the lowers position
    // Then I'll assign that poition to each paragraph
    const mergeParagraphs = async () => {
      const paragraphs = paragraphsToMerge.value;

      const positions = paragraphs.map((p) => {
        return p.paragraphPosition;
      });

      const sortedPositions = positions.sort();
      const lowestPosition = sortedPositions[0];

      for (var paragraph of paragraphs) {
        paragraph.paragraphPosition = lowestPosition;

        const index = allParagraphs.value.findIndex(
          (p) => p.id == paragraph.id
        );

        if (index < 0) continue;

        allParagraphs.value[index] = paragraph;
      }

      await saveParagraphs(paragraphs, undefined, true);
      stopMerging();

      allParagraphs.value = [...allParagraphs.value];

      useDocumentChecklistStore(documentId).reinferAllCriteria();
    };

    const stopMerging = () => {
      paragraphsToMerge.value = [];
    };

    const isParagraphToBeMerged = computed(() => {
      return (paragraph: Paragraph) => {
        return (
          paragraphsToMerge.value.findIndex((p) => p.id == paragraph.id) >= 0
        );
      };
    });

    const setParagraphIdToBodyPosition = (
      paragraphId: string,
      position: number
    ) => {
      if (
        paragraphIdToBodyPositionMap.value[paragraphId] &&
        paragraphIdToBodyPositionMap.value[paragraphId] == position
      ) {
        return;
      }

      paragraphIdToBodyPositionMap.value[paragraphId] = position;
    };

    const mappedBodyPositionFromParagraphId = (
      paragraphId: string
    ): number | undefined => {
      return paragraphIdToBodyPositionMap.value[paragraphId];
    };

    const paragraphIdFromPosition = computed(() => {
      return (position: number) => {
        return bodyParagraphs.value.find(
          (p) => p.bodyParagraphPosition == position
        )?.id;
      };
    });

    const paragraphPositionFromParagraphId = computed(() => {
      return (paragraphId: string) => {
        return bodyParagraphs.value.findIndex((p) => p.id == paragraphId);
      };
    });

    // Funcitons for fixing up missing assignments

    const isFixingAssignment = ref(false);
    const paragraphToInsert = ref<Paragraph | undefined>(undefined);

    const toggleIsFixingAssignment = (isFixing?: boolean) => {
      isFixingAssignment.value =
        isFixing != undefined ? isFixing : !isFixingAssignment.value;
    };

    const hasSelectedParagraphToInsert = computed(() => {
      return paragraphToInsert.value != undefined;
    });

    const setParagraphToInsert = (paragraph: Paragraph) => {
      paragraphToInsert.value = paragraph;
    };

    const isParagraphSetToInsert = computed(() => {
      return (paragraph: Paragraph) => {
        return paragraphToInsert.value?.id == paragraph.id;
      };
    });

    // Oh interesting. I wonder if I should delete all the tokens? Why am I doing that anyways? to make sure they don't get out of sync? I guess I could just fetch all paragraphs again as part of this process to be safe. That probably makes sense.
    const fixAssignment = async () => {
      const paragraphId = paragraphToInsert.value?.id;

      if (!paragraphId) return;

      // First, splice all paragraphs after this one
      const sectionParagraphs = await fetchParagraphs("sections");
      const index = sectionParagraphs.findIndex((p) => p.id == paragraphId);

      if (index < 0) return;

      const paragraphsToMove = sectionParagraphs.splice(index);

      // Delete Paragraphs To move from sections AND updates allParagraphs to split that too.
      const db = useFirestore();
      const batch = writeBatch(db);

      const trimmedParagraphs = useTrimParagraphs(paragraphsToMove);

      for (var paragraph of trimmedParagraphs) {
        const sectionParagraphRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/sections/${paragraph.id}`
        );

        batch.delete(sectionParagraphRef);

        paragraph.collectionRef = "paragraphs";
        paragraph.paragraphPosition = paragraph.index;

        const paragraphRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/paragraphs/${paragraph.id}`
        );

        batch.set(paragraphRef, paragraph);
      }

      await batch.commit();

      await reparseParagraphs();

      isFixingAssignment.value = false;
    };

    const deleteParagraphs = async (paragraphs: Paragraphs) => {
      const db = useFirestore();
      const batch = writeBatch(db);

      for (var paragraph of paragraphs) {
        const ref = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/${paragraph.collectionRef}/${paragraph.id}`
        );
        batch.delete(ref);
      }

      await batch.commit();
      await initializeParagraphs();
    };

    // Title Paragraph Selectors
    const paragraphBeforeTitleId = computed(() => {
      const titleParagraphIndex = allParagraphs.value.findIndex((p) => {
        return p.section == "title";
      });

      if (titleParagraphIndex < 0) return undefined;

      return allParagraphs.value[titleParagraphIndex - 1]?.id;
    });

    const hasParagraphBeforeTitle = computed(() => {
      return paragraphBeforeTitleId.value != undefined;
    });

    const paragraphBeforeConclusionId = computed(() => {
      // last body paragrpah index
      const lastBodyParagraphIndex = bodyParagraphs.value.length - 2;
      return bodyParagraphs.value[lastBodyParagraphIndex]?.id;
    });

    const hasParagraphBeforeConclusion = computed(() => {
      return paragraphBeforeConclusionId.value != undefined;
    });

    // Geginning Paragraph
    const hasParagraphs = computed(() => {
      return paragraphs.value.length > 0;
    });

    const firstParagraphId = computed(() => {
      return paragraphs.value[0]?.id;
    });

    const reparseParagraphs = async () => {
      await useFetch(`/api/${documentId}/${revisionId}/paragraphs/reparse`, {
        method: "GET",
        headers: await useApiHeaders(),
      });

      await initializeParagraphs();
    };

    return {
      paragraphs,
      // bodyParagraphs,
      // numBodyParagraphs,
      initializeParagraphs,
      saveTokens,
      saveParagraph,
      saveParagraphs,
      // deleteParagraphs,

      paragraphsToMerge,
      isMergingParagraphs,
      isParagraphToBeMerged,
      toggleParagraphToMerge,
      mergeParagraphs,
      stopMerging,

      paragraphIdToBodyPositionMap,
      setParagraphIdToBodyPosition,
      mappedBodyPositionFromParagraphId,
      paragraphIdFromPosition,
      paragraphPositionFromParagraphId,

      isMissingAssignment,
      isFixingAssignment,
      hasSelectedParagraphToInsert,
      fixAssignment,
      isParagraphSetToInsert,
      setParagraphToInsert,
      toggleIsFixingAssignment,

      paragraphBeforeTitleId,
      hasParagraphBeforeTitle,
      hasParagraphs,
      firstParagraphId,
      paragraphBeforeConclusionId,
      hasParagraphBeforeConclusion,

      reparseParagraphs,
    };
  });
